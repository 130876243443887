@import 'colors/mixins';
@import 'colors/palettes';
@import 'colors/palettes-variables';

// Generate classes for each pallet color
@include generate-palettes();

// Buttons
button {
  @each $key, $value in $theme-colors {
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button {
      &.mat-#{$key} {
        color: mat.get-color-from-palette($value, default-contrast);
      }
    }

    //&.mat-mdc-button {
    //  &.mat-#{$key} .mat-mdc-button-persistent-ripple {
    //    background-color: mat.get-color-from-palette($value, 50);
    //  }
    //}

    &.mdc-icon-button {
      &.mat-#{$key} {
        background: mat.get-color-from-palette($value, 50);
        //border: 1px solid mat.get-color-from-palette($value, 50);
      }
    }

    &.mat-mdc-outlined-button {
      &.mat-#{$key} {
        background: mat.get-color-from-palette($value, 300);
        color: mat.get-color-from-palette($value, default-contrast);
      }
      // &.mat-warn {
      //   background-color: red;
      //   color: white;
      // }
    }
  }
}

// Pipeline headers
.su-pl-in-queue,
.re-pl-in-queue,
.sa-pl-processing,
.re-pl-processing {
  background-color: #e5f4f0;
}

.su-pl-in-execution,
.sa-pl-open,
.re-pl-processing {
  background-color: #fee474;
}

.su-pl-blocked,
.sa-pl-cancelled,
.re-pl-cancelled {
  background-color: #f28f26;
}

.su-pl-executed {
  background-color: #d7eaca;
}

.su-pl-done,
.re-pl-done,
.sa-pl-closed {
  background-color: $accent-color;
}

.fullyops-theme {
  .gray {
    color: #6e6b7b;
  }

  .blue-icon {
    color: $primary-color;
  }

  .gray-icon {
    color: #6e6b7b;
  }

  .green-icon {
    color: #309f5c;
  }

  .red-icon {
    color: #da6b63;
  }

  .white-icon {
    color: white;
  }
}

@import 'colors/mixins';
@import 'colors/palettes';
@import 'colors/palettes-variables';

// Generate classes for each pallet color
@include generate-palettes();

// Buttons
button {
  @each $key, $value in $theme-colors {
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button {
      &.mat-#{$key} {
        color: mat.get-color-from-palette($value, default-contrast);
      }
    }

    //&.mat-mdc-button {
    //  &.mat-#{$key} .mat-mdc-button-persistent-ripple {
    //    background-color: mat.get-color-from-palette($value, 50);
    //  }
    //}

    &.mdc-icon-button {
      &.mat-#{$key} {
        background: mat.get-color-from-palette($value, 50);
        //border: 1px solid mat.get-color-from-palette($value, 50);
      }
    }
  }
}

// Pipeline headers
.su-pl-in-queue,
.re-pl-in-queue,
.sa-pl-processing,
.re-pl-processing {
  background-color: #e5f4f0;
}

.su-pl-in-execution,
.sa-pl-open,
.re-pl-processing {
  background-color: #fee474;
}

.su-pl-blocked,
.sa-pl-cancelled,
.re-pl-cancelled {
  background-color: #f28f26;
}

.su-pl-executed {
  background-color: #d7eaca;
}

.su-pl-done,
.re-pl-done,
.sa-pl-closed {
  background-color: $accent-color;
}

.fullyops-theme {
  .gray {
    color: #6e6b7b;
  }

  .blue-icon {
    color: $primary-color;
  }

  .gray-icon {
    color: #6e6b7b;
  }

  .green-icon {
    color: #309f5c;
  }

  .red-icon {
    color: #da6b63;
  }

  .white-icon {
    color: white;
  }
}
