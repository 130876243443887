// Primary Palette
$primary-default-light: 600;
$primary-palette: mat.define-palette(
  $fullyops-blue-palette,
  $primary-default-light
);
$primary-color: mat.get-color-from-palette(
  $primary-palette,
  $primary-default-light
);
$primary-color-light: mat.get-color-from-palette($primary-palette, 50);

// Accent Palette
$accent-default-light: 200;
$accent-palette: mat.define-palette(
  $fullyops-teal-palette,
  $accent-default-light
);
$accent-color: mat.get-color-from-palette(
  $accent-palette,
  $accent-default-light
);

// Warn Palette
$warn-default-light: 500;
$warn-palette: mat.define-palette(mat.$red-palette, $warn-default-light);
$warn-color: mat.get-color-from-palette($warn-palette, $warn-default-light);

$theme-colors: (
  primary: $primary-palette,
  accent: $accent-palette,
  warn: $warn-palette,
);

// Text Color
$dark-color: mat.get-color-from-palette(mat.$grey-palette, 800);
$light-color: mat.get-color-from-palette(mat.$grey-palette, 50);
