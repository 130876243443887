@use '@angular/material' as mat;

@import 'core';
@import 'colors';
@import 'typography';
@import 'variables';

@include mat.core();

.fullyops-theme {
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.14) !important;

  $theme: mat.define-light-theme(
    (
      color: $theme-colors,
      typography: $typography-config,
      density: 0,
    )
  );

  @include mat.all-component-themes($theme);
  // @include mat.all-legacy-component-themes($theme);
  // @include mat.legacy-typography-hierarchy($theme);
  @include mat.all-component-themes($theme);
  @include mat.typography-hierarchy($theme);

  // TODO: Remove from theme.scss
  app-login,
  app-forgot-password,
  app-reset-password {
    mat-progress-bar {
      position: absolute;
      top: 0;
      left: 0;

      .mdc-linear-progress__bar-inner {
        color: white;
        border-color: white;
      }
    }

    form.login {
      .mat-mdc-text-field-wrapper {
        background-color: transparent;

        &:not(.mdc-text-field--focused) {
          .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border-color: white;
            }
          }
        }
      }

      .mat-form-field-appearance-outline .mat-form-field-outline,
      .mat-form-field-appearance-outline.mat-focused
        .mat-form-field-outline-thick {
        color: white;
      }

      input.mat-mdc-input-element {
        color: white;
        margin-top: 2px;
      }
      label {
        color: white !important;
        padding-top: 5px;
      }

      mat-form-field {
        width: 100%;
        margin-bottom: 25px;

        .mat-form-field-wrapper {
          margin-bottom: 10px;

          .mat-form-field-flex {
            height: 50px;
            background-color: transparent !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .mat-tab-label {
    min-width: 120px;
  }

  .mat-badge-small.mat-badge-above .mat-badge-content {
    top: -4px;
  }

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mat-ripple.mat-elevation-z4 {
    box-shadow: none;
  }

  .mat-elevation-z6 {
    box-shadow: none;
  }

  .primary {
    background-color: mat.get-color-from-palette($primary-palette);
    color: #fff;
  }

  .secondary {
    background-color: mat.get-color-from-palette($accent-palette);
    color: #fff;
  }

  .warn {
    background-color: mat.get-color-from-palette($warn-palette);
    color: #fff;
  }

  .primary-color {
    color: mat.get-color-from-palette($primary-palette);
  }

  .secondary-color {
    color: mat.get-color-from-palette($accent-palette, 500);
  }

  .warn-color {
    color: mat.get-color-from-palette($warn-palette);
  }

  // Priorities
  .mat-URGENT {
    background-color: #f28f26;
    color: #fff;
  }

  .mat-HIGH {
    background-color: #fecb93;
  }

  .mat-MEDIUM {
    background-color: #fee474;
  }

  .mat-LOW {
    background-color: #d7eaca;
  }

  .mat-label {
    text-align: center;
    border-radius: 20px;
    border: none;
    padding: 3px 15px;
    margin-right: 5px;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: 200;
    max-width: 125px;
  }

  .mat-snack-bar-container {
    background-color: mat.get-color-from-palette($warn-palette);
    color: #fff;
  }

  mat-vertical-stepper.mat-stepper-vertical {
    background-color: transparent;
  }

  --mdc-dialog-subhead-size: 20px;

  .mat-dialog-container {
    transform: none;
    box-shadow: none;
    border: 1px solid #e4e3e6;
    border-radius: 5px;
    background: white;
  }
  mat-accordion.mat-accordion mat-expansion-panel.mat-expansion-panel {
    box-shadow: none;
    border: 1px solid #e4e3e6;
    border-radius: 5px !important;
    margin-bottom: 8px;
    box-sizing: border-box;
  }

  mat-expansion-panel-header {
    font-size: 16px;
    height: 44px;
  }

  @media only screen and (max-width: 1200px) {
    .mat-label {
      font-size: 11px;
    }
  }

  mat-card {
    box-shadow: none;
    border: 1px solid #e4e3e6;
  }

  .mat-header-cell {
    color: rgba(3, 0, 71, 1);
    font-weight: 600;
  }

  // Cells
  mat-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 18px;

    &.align-center {
      align-items: center;
    }

    .cell-row {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
    }

    span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;

      &.title {
        font-size: 13px;
        line-height: 22px;
        max-height: 22px;
        overflow: hidden;

        img {
          width: 16px;
          height: 16px;
        }
      }

      &.subtitle {
        font-size: 11px;
        line-height: 20px;
        max-height: 20px;
        overflow: hidden;
        color: $primary-color;
      }

      &.tag {
        border-radius: 4px;
        font-size: 11px;
        line-height: 14px;
        padding: 3px 7px;
        background-color: #5d8fca26;
        color: $primary-color;
        margin: 4px 0;
        max-height: 24px;
        overflow: hidden;

        &.success {
          background-color: #ddf6e8;
          color: #29c76f;
          font-size: 13px;
          line-height: 14px;
          padding: 5px 10px;
        }
      }
    }

    mat-icon {
      font-size: 15px;
      line-height: 26px;
      border-radius: 50%;
      text-align: center;
      color: $primary-color;
      background: mat.get-color-from-palette($primary-palette, 50);
      max-width: 15px;

      &:not(.rounded) {
        background: none;
      }

      &.done {
        color: #28c76f;
        background: rgba(40, 199, 111, 0.16);
      }
    }
  }

  mat-chip-grid.selected-filters {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    .mat-chip-grid-wrapper {
      flex-wrap: nowrap;
      justify-content: flex-end;

      mat-chip {
        font-size: 12px;
        font-weight: 200;
        padding: 2px 7px 2px 10px;
        min-height: 28px;

        b {
          font-weight: 400;
          margin-right: 3px;
        }
      }
    }
  }

  mat-chip-list.autocomplete-chips {
    .mat-chip-list-wrapper:has(> mat-chip) {
      padding-top: 8px !important;
    }
    mat-chip {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 900px) {
    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
      padding-left: 12px;
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
      padding-right: 12px;
    }

    mat-cell {
      gap: 8px;

      span {
        &.title {
          font-size: 13px;
          line-height: 16px;
          max-height: 32px;
        }

        &.subtitle {
          font-size: 10px;
          line-height: 16px;
          max-height: 20px;

          &.desktop-only {
            display: none;
          }
        }
      }
    }
    mat-chip-grid.selected-filters {
      .mat-chip-grid-wrapper {
        justify-content: flex-start;
      }
    }
  }

  @media only screen and (max-width: 479px) {
    mat-chip-grid.selected-filters {
      .mat-chip-grid-wrapper {
        mat-chip {
          font-size: 10px;
          padding: 0px 4px 0px 8px;
          min-height: 22px;

          mat-icon {
            width: 16px;
            height: 16px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

snack-bar-container.mat-snack-bar-container.mat-snack-bar-center {
  border: 1px solid #e4e3e6;
  border-radius: 20px;
  box-shadow: none;
  overflow: hidden;
  margin-bottom: 15px;
}

.mat-fab,
.mat-mini-fab,
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6,
.mat-dialog-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.mat-toolbar {
  box-shadow: none;
  border: 1px solid #e4e3e6;

  mat-toolbar-row {
    font-size: 18px;
    height: 56px;
  }

  &.mat-toolbar-multiple-rows {
    min-height: 56px;
  }
}

.clickable-calendar-item {
  cursor: pointer;
}

.calendar-weekend-background {
  background: #f0f0f0;
}

// Disable the yellow highlighting for the current date in day view.
.fc-timeGridDay-view .fc-timegrid-col.fc-day-today {
  background-color: inherit;
}

// This is an internal class used by FullCalendar; it marks the actual
// div that contains the event title text, and so is where the text-overflow
// property must be for it to work.
.fc-event-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // This was the only way we could find of inserting a Material icon before
  // the title without disabling the rest of FullCalendar's layout and presentation
  // for events; the eventContent render hook in particular does too much, as it
  // replaces the entire content of the event box rather than just the title.
  // The CSS rules here are similar to what mat-icon uses internally to display
  // the icon; these icons are really just ligatures in a specially-crafted font,
  // and so we can use a content rule to insert text which the Material Icons font
  // turns into an icon.
  // The main difference is the use of font-size: inherit to keep the icon in line
  // with the size of the surrounding text, so that the layout looks natural and the
  // icon doesn't take too much size.
  // The actual icon to insert is selected by one of several
  // predefined classes beginning with calendar-event-with-icon-; these are applied
  // to the overall calendar item using the eventClassNames render hook.
  &::before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }
}

// Each icon to be used in a calendar entry must be listed here.
@each $icon in call, email, timelapse, people, assignment, engineering {
  .calendar-event-with-icon-#{$icon} {
    .fc-event-title::before {
      content: '#{$icon}';
    }
  }
}

// Make the `+N` event links look approximately like normal event links.
.calendar-more-link {
  display: inline-block;
  border-radius: 3px;
  background-color: var(--fc-event-bg-color);
  color: var(--fc-event-text-color);
}

.calendar-view-menu {
  width: 400px;
  overflow: hidden !important;
}

.breadcrumb {
  button {
    cursor: pointer;
    margin-left: 15px;
    text-size-adjust: none;
  }

  a.mat-menu-item,
  button.mat-menu-item,
  span.mat-menu-item {
    // padding: 0 15px 0 0;
    font-weight: 400;
    font-size: 18px;
    color: #949494;

    &.current {
      cursor: default;
    }

    &.separator {
      cursor: default;

      mat-icon {
        margin: 0;
        width: 20px;
        vertical-align: middle;
        font-size: 20px;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &:hover:not(.current) {
      color: #676767;
    }
  }
}

div.mat-menu-panel {
  position: relative;
  // top: 45px;
  // left: 22px;
  border-radius: 5px;
  min-width: 200px;
}
div.mat-select-panel {
  position: relative;
  border-radius: 5px;
  min-width: 200px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 38px;
  font-size: 14px;
  font-weight: 400;
}

.fullyops-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary-color;
}

.spacer {
  flex: 1 1 auto;
}

.mat-grid-tile {
  &.align-left .mat-figure {
    justify-content: flex-start;
  }

  &.align-right .mat-figure {
    justify-content: flex-end;
  }
}

.mat-sort-header-container {
  padding-left: 18px;
  text-align: center;
  justify-content: center;
}

/* Forms */

.form-dialog {
  &.fixed {
    crm-breadcrumb {
      display: block;
      position: fixed;
      width: 90%;
      z-index: 9;

      .mat-toolbar {
        border-radius: 20px 20px 0 0;
      }
    }
  }

  .mat-dialog-container {
    padding: 0;
    background-color: #fafafa;

    crm-breadcrumb {
      display: block;

      .mat-toolbar {
        padding: 5px;
      }
    }

    form {
      margin-top: 10px;
    }
  }
}

crm-loading,
crm-error {
  height: 400px;
}

.mat-vertical-stepper-content {
  display: inline;
}

.row {
  height: 100%;
  overflow: auto;
  padding: 4px 0;
}

form {
  // margin: 50px 0 0 0px;
  // padding: 20px calc(50% - 720px);
  border-radius: 5px;

  .form-row {
    display: flex;
    flex-wrap: wrap;
  }

  textarea {
    height: 100px;
  }

  .form-table {
    width: 100%;
    margin-bottom: 20px;

    tr {
      height: 36px;

      &:nth-child(even) {
        background: whitesmoke;
      }

      &:nth-child(odd) {
        background: white;
      }

      th {
        button {
          float: right;
          position: absolute;
          bottom: -2px;
          right: 5px;
        }
      }

      td,
      th {
        position: relative;
      }
    }
  }
}

// @media only screen and (max-width: 1680px) {
//   form,
//   mat-tab-group {
//     padding: 30px;
//     padding-top: 0;
//   }
// }

// 2021 Rebrand
//
// Material Overrides
//

section header {
}

// LOGIN
// FORGOT-PASSWORD
//app-reset-password

app-login,
app-forgot-password,
app-reset-password {
  .mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.historyTooltip {
  font-size: 1em; // your custom properties here.
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.overflow-auto {
  overflow: auto;
}

// Layout
.fullyops-container {
  padding: 0 calc(50% - 720px) !important;
  max-width: 100%;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 767px) {
  .mat-mdc-dialog-panel {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .mat-mdc-dialog-content {
    flex-grow: initial !important;
  }

  .mdc-dialog__actions {
    padding: 0 20px 24px !important;
  }
}

@media (max-width: 1100px) {
  app-travel-card-v2 {
    .mat-form-field-invalid {
      margin-bottom: 25px;
    }
  }
}

.icon-blue {
  color: #5d8fca;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-w30 {
  width: 30px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.green-icon {
  color: #309f5c;
}

.blue-icon {
  color: #5d8fca;
}

.red-icon {
  color: #da6b63;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

button:disabled .icon.red-icon {
  color: #00000042;
}

@mixin button-style {
  transition: transform 0.3s ease-out, opacity 0.3s;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.button-style {
  @include button-style;

  &.green {
    background-color: #309f5c;
    color: white;
  }

  &.red {
    background-color: #da6b63;
    color: white;
  }
}

.breadcrumb-container {
  max-width: 1280px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  justify-content: space-between;
}

.nopadding {
  padding: 0 !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.remove-padding {
  padding: 0;
}

.centered-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
