@import '../variables.scss';

@mixin palette-background-color($palette-name, $hue) {
  $palette: mat.define-palette($palette-name, $hue);

  background-color: map-get($palette, 'default');
  color: map-get($palette, 'default-contrast');
}

@mixin palette-color($palette-name, $hue) {
  $palette: mat.define-palette($palette-name, $hue);

  color: map-get($palette, 'default');
}

@mixin generate-palette($palette-name, $palette) {
  $hueList: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;

  @each $hue in $hueList {
    .#{$palette-name}-#{$hue}:not(.mat-form-field),
    .mat-form-field.#{$palette-name}-#{$hue} .mat-form-field-outline {
      @include palette-background-color($palette, $hue);
    }

    .color-#{$palette-name}-#{$hue} {
      @include palette-color($palette, $hue);
    }
  }
}

@mixin generate-palettes() {
  $paletteList: (
    'red': mat.$red-palette,
    'pink': mat.$pink-palette,
    'purple': mat.$purple-palette,
    'deep-purple': mat.$deep-purple-palette,
    'indigo': mat.$indigo-palette,
    'blue': mat.$blue-palette,
    'light-blue': mat.$light-blue-palette,
    'cyan': mat.$cyan-palette,
    'teal': mat.$teal-palette,
    'green': mat.$green-palette,
    'light-green': mat.$light-green-palette,
    'lime': mat.$lime-palette,
    'yellow': mat.$yellow-palette,
    'amber': mat.$amber-palette,
    'orange': mat.$orange-palette,
    'deep-orange': mat.$deep-orange-palette,
    'brown': mat.$brown-palette,
    'grey': mat.$grey-palette,
    'blue-grey': mat.$blue-grey-palette,
  );

  @each $name, $palette in $paletteList {
    @include generate-palette($name, $palette);
  }
}
