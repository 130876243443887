@import 'variables';

.fullyops-theme {
  .mat-body-1,
  .mat-body-2 {
    margin: 0 !important;
  }

  // Expansion Panel
  .mat-expansion-indicator {
    line-height: 5px;

    &::after {
      vertical-align: super;
    }
  }

  .mat-expansion-panel-content {
    border-top: 1px solid #e4e3e6;
    border-radius: 0;
  }

  .mat-expansion-panel-body {
    padding: 16px;
  }

  // Menu
  .mat-mdc-menu-panel {
    box-shadow: 0 0.25rem 1rem rgba(165, 163, 174, 0.45) !important;
    border-radius: 0.375rem;

    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  // Form
  .mat-mdc-unelevated-button {
    padding: 0 10px;
    height: 32px;
  }

  // Buttons
  button {
    height: 32px;
    border-radius: $border-radius;
    display: inline-flex;
    align-items: center;

    &.mat-mdc-icon-button {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;

      .mat-mdc-button-persistent-ripple {
        border-radius: $border-radius;
      }

      &.mat-mdc-button-base {
        align-items: center;
        display: inline-flex;
        gap: 6px;
        height: 40px;
        padding: 6px;
        width: initial;

        fa-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          height: 15px;
        }

        &.big-icon svg {
          height: 24px;
        }
      }
    }
  }
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 12px;
  }

  .mat-mdc-form-field {
    position: relative;
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: relative;
    text-align: start;
    pointer-events: none;

    &:before {
      display: none;
    }

    mat-error {
      padding-top: 5px;
      &:before {
        display: none;
      }
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      position: relative;
    }
  }

  .mat-mdc-text-field-wrapper {
    background-color: white;

    &.mdc-text-field--outlined {
      div.mat-mdc-form-field-infix {
        padding-top: 8px;
        padding-bottom: 6px;
        min-height: 38px;
        line-height: 25px;
      }

      div.mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 19px;
      }

      div.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-24.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }
    }
  }

  //Mat Tabs
  .header-less-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
    display: none;
  }

  .mat-mdc-tab-header {
    align-items: center;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    gap: 12px;
  }

  mat-tab-group {
    // padding: 20px calc(50% - 720px);
    border-radius: 5px;

    form {
      margin: 0;
      padding: 0;
    }

    &.margin-0 {
      mat-tab-header {
        margin: 0;
      }
    }

    mat-tab-header {
      margin-bottom: 30px;
    }

    .mat-tab-body-content {
      padding-top: 10px;
    }
  }
}
