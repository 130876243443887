$font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

$h1-typography: mat.define-typography-level(38px, 52px, 500);

$h2-typography: mat.define-typography-level(32px, 44px, 500);

$h3-typography: mat.define-typography-level(26px, 36px, 500);

$h4-typography: mat.define-typography-level(22px, 30px, 500);

$h5-typography: mat.define-typography-level(18px, 24px, 500);

$h6-typography: mat.define-typography-level(15px, 21px, 500);

$text-xs-typography: mat.define-typography-level(11px, 22px, 400);
$text-sm-typography: mat.define-typography-level(13px, 22px, 400);
$text-md-typography: mat.define-typography-level(15px, 22px, 400);
$text-lg-typography: mat.define-typography-level(18px, 22px, 400);

$caption-typography: mat.define-typography-level(11px, 14px, 400);

$typography-config: mat.define-typography-config(
  $font-family: $font-family,
  $headline-5: $h1-typography,
  $headline-6: $h2-typography,
  $subtitle-1: $h3-typography,
  $subtitle-2: $h5-typography,
  $body-1: $text-md-typography,
  $body-2: $text-sm-typography,
  $caption: $caption-typography,
);

// Tables
mat-header-cell {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  // text-transform: uppercase;
}

.lead {
  font-size: 18px;
  font-weight: 28px;
}

.thin {
  font-weight: 100;
}

.xlight {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.small {
  font: normal normal 400 13px/20px $font-family, serif;
}

.small-medium {
  font: normal normal 500 13px/14px $font-family, serif;
}

.small-bold {
  font: normal normal 700 13px/20px $font-family, serif;
}

// Font Styles

.delete {
  font: normal normal 400 15px/24px $font-family, serif;
  text-decoration-line: strikethrough;
}

.italicized {
  font: normal normal 400 15px/24px $font-family, serif;
  text-transform: lowercase;
}

.underline {
  font: normal normal 400 16px/24px $font-family, serif;
  text-decoration-line: underline;
}

.caption {
  font: normal normal 400 11px/14px $font-family, serif;
}
