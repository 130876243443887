$border-radius: 6px;

// Table
$table-border-color: #dbdade;
$table-header-bg-color: whitesmoke;
$table-row-hover-color: whitesmoke;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

:root {
  --mat-expansion-header-text-size: 14px;
}

$breakpoints: (
  'sm': 640px,
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  '2xl': 1536px,
);

$configurations-width: 75%;
$configurations-alignment: center;
